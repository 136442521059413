import { MenuItem } from 'src/types/menu'

export const menu: MenuItem[] = [
  {
    label: 'telescope',
    id: 'telescope',
    icon: 'mdi-laravel',
    permission: 'telescope_view',
    old: 'telescope',
    class: 'bg-deep-purple-8 text-white',
  },
  {
    label: 'home',
    route: 'mBot',
    icon: 'mdi-home-outline',
    old: 'dashboard',
  },
  {
    label: 'customers',
    route: 'customers',
    icon: 'mdi-account-group-outline',
    permission: 'customer_manage',
    old: 'customers',
  },
  {
    label: 'agents',
    route: 'agents',
    permission: 'agent_manage',
    icon: 'mdi-account-child-outline',
    old: 'agents',
  },
  {
    label: 'classes_merch',
    route: 'classesMerch',
    icon: 'mdi-format-list-group',
    permission: 'classes_merch_access',
    menu: true,
    open: false,
    items: [
      {
        label: 'classes_merch_cluster',
        route: 'classesMerchClusterIndex',
        icon: 'mdi-atom-variant',
        permission: 'classes_merch_clusters_manage',
      },
    ],
  },
  {
    label: 'cross_analyzer',
    route: 'crossAnalyzer',
    icon: 'mdi-chart-areaspline',
    permission: 'cross_analyzer_access',
    old: 'cross-analyzer',
  },
  {
    label: 'order_bot',
    route: 'orderBot',
    icon: 'mdi-package-variant',
    permission: 'order_bot_access',
    menu: true,
    open: false,
    items: [
      {
        label: 'order_bot_settings',
        route: 'orderBotSettings',
        icon: 'mdi-store-cog',
        permission: 'order_bot_manage',
      },
      {
        label: 'order_bot_manage_white_lists',
        route: 'orderBotManageWhiteLists',
        icon: 'mdi-traffic-light-outline',
        permission: 'order_bot_manage',
      },
      {
        label: 'manage_warehouse_distributions',
        route: 'manageWarehouseDistributions',
        icon: 'mdi-warehouse',
        permission: 'order_bot_manage',
      },
      {
        label: 'warehouses_refilling_levels',
        route: 'warehousesRefillingLevels',
        icon: 'mdi-format-wrap-square',
        permission: 'order_bot_manage',
      },
      {
        label: 'coefficients_thresholds_branch',
        route: 'coefficientsThresholdsBranch',
        icon: 'mdi-numeric-5-box-multiple-outline',
        permission: 'order_bot_manage',
      },
      {
        label: 'five_thresholds_coefficients',
        route: 'fiveThresholdsCoefficients',
        icon: 'mdi-numeric-5-box-multiple-outline',
        permission: 'order_bot_manage',
      },
      {
        label: 'five_thresholds_constraints',
        route: 'fiveThresholdsConstraints',
        icon: 'mdi-lock',
        permission: 'order_bot_manage',
      },
      {
        label: 'economic_order_quantity',
        route: 'economicOrderQuantity',
        icon: 'mdi-square-circle',
        permission: 'order_bot_manage',
      },
      {
        label: 'warehouses_hierarchies_classes_merchs',
        route: 'warehousesHierarchiesClassesMerchs',
        icon: 'mdi-safe-square-outline',
        permission: 'order_bot_manage',
      },
    ],
  },
  {
    label: 'reward_bot',
    route: 'rewardBot',
    icon: 'mdi-trophy-outline',
    permission: 'reward_bot_access',
    old: 'reward-bot',
  },
  {
    label: 'q_bot',
    route: 'qBot',
    icon: 'mdi-chart-timeline',
    permission: 'q_bot_access',
    menu: true,
    open: false,
    items: [
      {
        label: 'q_bot_revenue',
        route: 'qBotRevenue',
        icon: 'mdi-account-tie',
        old: 'q-bot/agents-areas',
      },
    ],
  },
  {
    label: 'bid_bot',
    route: 'bidBot',
    icon: 'mdi-help-network',
    permission: 'bid_bot_access',
    menu: true,
    open: false,
    items: [
      {
        label: 'bid_bot_manage',
        route: 'bidBotManage',
        icon: 'mdi-format-list-text',
        permission: 'bid_bot_manage',
        old: 'bid-bot/list',
      },
      {
        label: 'bid_bot_send',
        route: 'bidBotSend',
        icon: 'mdi-send-outline',
        permission: 'bid_bot_send',
        old: 'bid-bot/send',
      },
    ],
  },
  {
    label: 'x_bot',
    route: 'xBot',
    icon: 'mdi-shuffle-variant',
    permission: 'x_bot_access',
    menu: true,
    open: false,
    items: [
      {
        label: 'x_bot_import',
        route: 'xBotImport',
        icon: 'mdi-upload',
        old: 'x-bot/upload',
      },
      {
        label: 'x_bot_cross',
        route: 'xBotCross',
        icon: 'mdi-auto-fix',
        old: 'x-bot/cross',
      },
    ],
  },
  {
    label: 'suppliers',
    route: 'suppliers',
    icon: 'mdi-truck-outline',
    permission: 'supplier_show',
    menu: true,
    open: false,
    items: [
      {
        label: 'pfa_rules',
        route: 'pfaRules',
        icon: 'mdi-trophy-award',
        permission: 'pfa_show',
      },
      {
        label: 'promo',
        route: 'supplierPromo',
        icon: 'mdi-bullhorn-outline',
        permission: 'supplier_promo_show',
      },
    ],
  },
  {
    label: 'carbody',
    route: 'carBody',
    icon: 'mdi-car-door',
    permission: 'car_body_prices_index',
    menu: true,
    open: false,
    items: [
      {
        label: 'carbody_upload',
        route: 'carBodyUpload',
        icon: 'mdi-upload',
        permission: 'car_body_prices_import',
      },
      {
        label: 'carbody_compare',
        route: 'carBodyCompare',
        icon: 'mdi-compare',
        permission: 'car_body_prices_index',
      },
      {
        label: 'carbody_manage_products',
        route: 'carBodyManageProducts',
        icon: 'mdi-store-plus-outline',
        permission: 'car_body_prices_index',
      },
      {
        label: 'carbody_edit_product',
        route: 'carBodyEditProduct',
        icon: 'mdi-clipboard-edit-outline',
        permission: 'car_body_prices_index',
      },
      {
        label: 'car_body_tmp_products_index',
        route: 'carBodyTmpProductsIndex',
        icon: 'mdi-new-box',
        permission: 'car_body_prices_index',
      },
    ],
  },
  {
    label: 'products_sizes',
    route: 'productsSizes',
    icon: 'mdi-package',
    permission: 'products_sizes_upload_measures',
  },
  {
    label: 'traders',
    route: 'traders',
    icon: 'mdi-registered-trademark',
    permission: 'traders_products_create',
  },
  {
    label: 'teccom',
    route: 'teccom',
    icon: 'mdi-package-variant',
    permission: 'teccom_submit_orders',
  },
]

export const managerMenu: MenuItem[] = [
  {
    label: 'manage',
    icon: 'mdi-cogs',
    permission: 'dashboard_manage',
    class: 'bg-blue-grey-3 text-blue-grey-10',
    menu: true,
    open: false,
    items: [
      {
        label: 'settings',
        route: 'settings',
        icon: 'mdi-tune',
        old: 'settings',
        permission: 'setting_manage',
      },
      {
        label: 'users',
        route: 'users',
        icon: 'mdi-account-supervisor-circle',
        old: 'users',
        permission: 'user_manage',
      },
      {
        label: 'roles',
        route: 'roles',
        icon: 'mdi-boom-gate',
        old: 'roles',
        permission: 'role_manage',
      },
      {
        label: 'tags',
        route: 'tags',
        icon: 'mdi-tag-multiple-outline',
        old: 'tags',
        permission: 'tag_manage',
      },
    ],
  },
]