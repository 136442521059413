const teccom = {
  steps: {
    select: 'Seleziona i parametri prezzo',
    order: 'Seleziona ordine',
    order_details: 'Dettagli ordine',
    send: 'Ordina'
  },
  supplier_select: 'Seleziona fornitore',
  download: 'Invia ordine',
  supplier_id: 'Fornitore',
  codice_magazzino: 'Magazzino',
  tof_codfor: 'Fornitore',
  tof_mag: 'Codice magazzino',
  tof_anno: 'Anno',
  tof_progr: 'Progressivo',
  tof_datord: 'Data',
  tof_tipord: 'Tipo',
  tof_cod_dest: 'Destinazione',
  tof_user_cod: 'Utente',
  fk_cod_for_cati: 'Codice fornitore CATI',
  stato: 'Stato',
  for_rag_soc_1: 'Nome fornitore',
  dof_codmag: 'Codice prodotto',
  mag_descr: 'Descrizione prodotto',
  dof_datsca: 'Scadenza ordine',
  dof_voce: 'Progressivo voce',
  dof_codiva_cog: 'Cod. IVA',
  dof_ord: 'Qta. Ord.',
  dof_riscontrata: 'Qta. riscontrata',
  dof_cons: 'Qta. consegnata',
  dof_costo: 'Costo',
  dof_sc: 'Sconto',
  supplier_details_text: 'Ordine per:',
  supplier_name: 'Nome fornitore',
  order_details: 'Dettagli ordine',
  num_items: 'Numero prodotti',
  total_amount_ordered: 'Quantita totale pezzi',
  total_expence: 'Importo totale',
  payment: 'Modalita di pagamento',
  address: 'Spedito a',
  courier: 'Corriere',
  back_to_home_button: 'Torna alla selezione ordini',
  status_code: 'Codice stato',
  status_message: 'Esito',
  status: 'Stato',
  guid: 'Codice transazione',
  datetime: 'Data e ora',
  citta_magazzino: 'Citta',
  tracked_status: 'Ordine registrato',
  message: 'Esiti invio ordini',
  order_resume: 'Riassunto ordini',
  tof_datcons: 'Data consegna',
  tof_datcons_placeholder: 'Cambia data consegna',
  ord_progr: 'Progressivi ordine',
  order_kind: 'Tipo ordine',
}

export default teccom
