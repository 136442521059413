import { RouteRecordRawArgumented } from 'src/types/route'
import useI18n from 'src/hooks/i18n'
import useRouteParser from 'src/hooks/routeParser'

const { t } = useI18n()
const { getPaginationCasts, getPaginationInject } = useRouteParser()

const module: RouteRecordRawArgumented = {
  path: 'warehouse-distributions',
  name: 'manageWarehouseDistributions',
  component: () =>
    import(
      /* webpackChunkName: "manage-warehouse-distributions" */ 'pages/order-bot/ManageWarehouseDistributions.vue'
    ),
  meta: {
    breadcrumb: {
      name: t('breadcrumb.order_bot.manage_warehouse_distributions'),
      icon: 'mdi-warehouse',
    },
    auth: {
      permission: 'order_bot_manage',
    },
    inject: {
      query: {
        ...getPaginationInject(),
        sort: 'created_at',
        dir: 'desc',
      },
    },
    casts: {
      query: {
        ...getPaginationCasts(),
      },
    },
  },
  children: [
    {
      path: 'upload-warehouse-levels',
      name: 'warehouseDistributionsUploadLevels',
      component: () =>
        import(
          /* webpackChunkName: "warehouse-distributions-upload-levels" */ 'pages/order-bot/WarehouseDistributionsUploadLevels.vue'
        ),
      meta: {
        breadcrumb: {
          icon: 'mdi-traffic-light',
          name: t(
            'breadcrumb.order_bot.create_forcing_distribution_project_massive'
          ),
        },
        auth: {
          permission: 'order_bot_manage',
        },
        casts: {
          query: {},
        },
      },
    },
  ],
}

export default module
