import { RouteRecordRawArgumented } from 'src/types/route'
import useRouteParser from 'src/hooks/routeParser'
import useI18n from 'src/hooks/i18n'

const { t } = useI18n()
const { getPaginationInject, getPaginationCasts, toArrayOfInt, toBoolean } =
  useRouteParser()

const module: RouteRecordRawArgumented = {
  path: 'carbody',
  name: 'carBody',
  redirect: () => ({ name: 'carBodyIndex' }),
  children: [
    {
      path: '',
      name: 'carBodyIndex',
      component: () =>
        import(
          /* webpackChunkName: "carbody-index" */ 'pages/carbody/IndexPage.vue'
        ),
      meta: {
        breadcrumb: {
          name: t('breadcrumb.carbody.index'),
          icon: 'mdi-land-rows-horizontal',
        },
        auth: {
          permission: 'car_body_prices_index',
        },
        inject: {
          query: {
            ...getPaginationInject(),
            sort: 'created_at',
            dir: 'desc',
            available: true
          },
        },
        casts: {
          query: {
            ...getPaginationCasts(),
            supplier_id: toArrayOfInt,
            available: toBoolean
          },
        },
      },
      children: [
        {
          path: 'upload',
          name: 'carBodyUpload',
          component: () =>
            import(
              /* webpackChunkName: "carbody-upload" */ 'pages/carbody/UploadPage.vue'
            ),
          meta: {
            breadcrumb: {
              icon: 'mdi-upload',
              name: t('breadcrumb.carbody.upload'),
            },
            auth: {
              permission: 'car_body_prices_import',
            },
          },
        },
        {
          path: 'compare',
          name: 'carBodyCompare',
          component: () =>
            import(
              /* webpackChunkName: "carbody-compare" */ 'pages/carbody/ComparePage.vue'
            ),
          meta: {
            breadcrumb: {
              icon: 'mdi-compare',
              name: t('breadcrumb.carbody.compare'),
            },
            inject: {
              query: {
                ...getPaginationInject(),
                sort: 'name',
                dir: 'asc',
                available: true
              },
            },
            auth: {
              permission: 'car_body_prices_index',
            },
            casts: {
              query: {
                ...getPaginationCasts(),
                model_id: toArrayOfInt,
                available: toBoolean
              },
            },
          },
        },
        {
          path: 'manage-products/:step?',
          name: 'carBodyManageProducts',
          component: () =>
            import(
              /* webpackChunkName: "carbody-manage-products" */ 'pages/carbody/ManageProducts.vue'
            ),
          meta: {
            breadcrumb: {
              icon: 'mdi-store-plus-outline',
              name: t('breadcrumb.carbody.manage_products'),
            },
            inject: {
              query: {
                active: true
              },
              params: {
                step: 'cars'
              }
            },
            auth: {
              permission: 'car_body_prices_index',
            },
            casts: {
              query: {
                active: toBoolean
              },
            },
          },
        },
        {
          path: 'edit-product/:step?',
          name: 'carBodyEditProduct',
          component: () =>
            import(
              /* webpackChunkName: "carbody-manage-products" */ 'pages/carbody/EditProduct.vue'
            ),
          meta: {
            breadcrumb: {
              icon: 'mdi-invoice-edit-outline',
              name: t('breadcrumb.carbody.edit_product'),
            },
            inject: {
              query: {
                active: true
              },
              params: {
                step: 'general'
              }
            },
            auth: {
              permission: 'car_body_prices_index',
            },
            casts: {
              query: {
                active: toBoolean
              },
            },
          },
        },
        {
          path: 'tmp-products-index',
          name: 'carBodyTmpProductsIndex',
          component: () =>
            import(
          /* webpackChunkName: "carbody-index" */ 'pages/carbody/TmpProductsIndex.vue'
            ),
          meta: {
            breadcrumb: {
              name: t('breadcrumb.carbody.tmp_products_index'),
              icon: 'mdi-new-box',
            },
            auth: {
              permission: 'car_body_prices_index',
            },
            inject: {
              query: {
                ...getPaginationInject(),
                sort: 'created_at',
                dir: 'desc',
              },
            },
            casts: {
              query: {
                ...getPaginationCasts(),
                supplier_id: toArrayOfInt,
              },
            },
          }
        },
      ],
    },
  ],
}

export default module