const sidebar = {
  home: 'Home',
  customers: 'Customers',
  agents: 'Agenti',
  carbody: 'Carrozzeria',
  carbody_upload: 'Carica Prezzi',
  carbody_compare: 'Confronta Prezzi',
  carbody_manage_products: 'Crea nuovo prodotto',
  carbody_edit_product: 'Modifica prodotto',
  car_body_tmp_products_index: 'Lista nuovi prodotti',
  classes_merch: 'Classi',
  classes_merch_cluster: 'Gestione Cluster',
  cross_analyzer: 'Cross Analyzer',
  order_bot: 'Order Bot',
  order_bot_settings: 'Configura',
  reward_bot: 'Reward Bot',
  q_bot: 'Q-Bot',
  q_bot_revenue: 'Agenti e Aree',
  bid_bot: 'Bid Bot',
  bid_bot_manage: 'Elenco richieste',
  bid_bot_send: 'Invia richiesta',
  x_bot: 'X-Bot',
  x_bot_import: 'Importa file',
  x_bot_cross: 'Genera file',
  manage: 'Gestione',
  pfa_rules: 'Premi annuali',
  promo: 'Promozioni',
  settings: 'Impostazioni',
  suppliers: 'Fornitori',
  users: 'Utenti',
  roles: 'Ruoli/Permessi',
  tags: 'Tags',
  telescope: 'Telescope Monitor',
  products_sizes: 'Dimensione Prodotti',
  traders: 'Traders',
  teccom: 'TecCom',
  order_bot_manage_white_lists: 'Gestione forzature',
  manage_warehouse_distributions: 'Gestione perimetro teorico',
  five_thresholds_coefficients: 'Coefficienti 5 soglie',
  five_thresholds_constraints: 'Vincoli 5 soglie',
  economic_order_quantity: 'Parametri EOQ',
  warehouses_refilling_levels: 'Gestione livelli refilling',
  warehouses_hierarchies_classes_merchs: 'Gerarchie classi merceologiche',
  coefficients_thresholds_branch: 'Riduzione Scorte di Sicurezza',
}

export default sidebar