import { RouteRecordRawArgumented } from 'src/types/route'
import useI18n from 'src/hooks/i18n'

// submodules
import compose from 'src/router/modules/order-bot/compose'
import order from 'src/router/modules/order-bot/order'
import settings from 'src/router/modules/order-bot/settings'
import forcingDistribution from 'src/router/modules/order-bot/forcing-distribution'
import warehouseDistributions from 'src/router/modules/order-bot/warehouse-distributions'
import fiveThresholdsCoefficients from 'src/router/modules/order-bot/five-thresholds-coefficients'
import fiveThresholdsConstraints from 'src/router/modules/order-bot/five-thresholds-constraints'
import economicOrderQuantity from 'src/router/modules/order-bot/economic-order-quantity'
import warehousesRefillingLevels from 'src/router/modules/order-bot/warehouse-refilling-levels'
import warehouseHierarchiesClassesMerchs from 'src/router/modules/order-bot/warehouses-hierarchies-classes-merchs'
import fiveThresholdsCoefficientsBranch from 'src/router/modules/order-bot/five-thresholds-coefficients-branch'

const { t } = useI18n()

const module: RouteRecordRawArgumented = {
  path: 'order-bot',
  name: 'orderBot',
  redirect() {
    return { name: 'orderBotCompose' }
  },
  meta: {
    auth: {
      permission: 'order_bot_access',
    },
    breadcrumb: {
      name: t('breadcrumb.order_bot.compose'),
      icon: 'mdi-package-variant',
    },
  },

  children: [
    compose,
    settings,
    order,
    forcingDistribution,
    warehouseDistributions,
    warehousesRefillingLevels,
    fiveThresholdsCoefficients,
    fiveThresholdsConstraints,
    economicOrderQuantity,
    warehouseHierarchiesClassesMerchs,
    fiveThresholdsCoefficientsBranch
  ],
}

export default module
