const order_bot = {
  buffer: 'Scorta',
  buffer_donators: 'Scorta donatori',
  buffer_forecast_title: 'Impostazioni base per il calcolo del fabbisogno',
  buffer_receptors: 'Scorta riceventi',
  buffer_warehouses: 'Scorta depositi',
  buffer_tooltip:
    'Inserisci un tempo diverso di scorta per i depositi donatori e quelli riceventi',
  buffers_options: {
    0: 'Nessuna',
    30: '30 giorni',
    45: '45 giorni',
    60: '60 giorni',
    75: '75 giorni',
    90: '90 giorni',
    105: '105 giorni',
    120: '120 giorni',
    150: '150 giorni',
    180: '180 giorni',
    360: '360 giorni',
  },
  cannot_submit_message:
    'Selezionare almeno un "Produttore" o una "Classe" per proseguire',
  clarios_products_groups: {
    U: 'Auto',
    F: 'Auto EFB',
    G: 'Auto AGM',
    T: 'Truck',
    L: 'Professional',
    M: 'Optima',
    X: 'Altro',
  },
  class_merch_or_manufacturer: 'Produttore/i o classe/i',
  compose: {
    title: 'Configurazione',
  },
  cpa: 'CPA',
  cpa_reference: 'Cpa di riferimento',
  cpa_tooltip: 'CPA periodo selezionato',
  customer_ordered: 'Ord. Clienti',
  customer_ordered_tooltip: 'Ordini cliente',
  destinations: 'Destinazioni',
  discount_extra: 'Extra sconto globale',
  discounts_classes_merch: 'Extra sconto per le classi selezionate',
  fab_x: {
    enable: 'Attiva il filtro',
    from_tolerance: 'Tolleranza',
    from_warehouse_number: 'Almeno in %{count} su %{selected} selezionati',
    title: 'Filtro Fab X',
    types_enabled: 'Tipologie abilitate',
    warehouses_reference: 'Magazzini di riferimento',
  },
  use_five_thresholds: "Utilizza l'algoritmo delle 5 soglie",
  include_new: 'Attiva i trasferimenti per i nuovi prodotti',
  five_thresholds: {
    logistics_classes: 'Classi logistiche',
    use_eoq: "Utilizza l'EOQ per ordinare",
    use_ordered: 'Considera gli ordini fornitore nella disponibilità logistica',
    centralized_orders_filter: 'Attiva il filtro ordini centralizzati',
    ordered_days_tolerance: 'Tollenzanda in giorni per la scadenza +/-',
    receptors: 'Classi logistiche abilitate al calcolo del fabbisogno',
    donators: 'Classi logistiche abilitate alla donazione',
    cdv_range: 'Includi se CdV compreso tra',
    cdv_range_min: 'Includi se CdV maggiore di',
    cdv_range_add_max: 'imposta un limite massimo',
    max_lt_days: 'Ignora se lead time maggiore di (giorni)',
    cpa_reference:
      "Cpa di riferimento per l'assegnazione della classe logistica",
    include_new: 'Analizza anche i nuovi prodotti',
    s_multipliers: 'Ricarica/diminuisci i valori di S in percentuale',
    s_multiplier: 'Percentuale ⁺∕₋',
    force_to_s: 'Riporta tutte le giacenze ad S',
  },
  orders_destinations: 'Modifica i depositi di destinazione degli ordini',
  orders_destination: 'Invia gli ordini al deposito di',
  from_warehouses: 'In arrivo da depositi',
  ignore_ta: 'Ignora i tempi di approvvigionamento',
  ignore_customers_orders: 'Ignora gli ordini clienti',
  low_move_buffer: 'Filtro bassa movimentazione',
  min_buffer: 'Scorta minima',
  moved_type: 'Mostra se',
  moved_type_options: {
    moved: 'Ordinare e/o trasferire',
    ordered: 'Solo se da ordinare',
    transfered: 'Solo se da trasferire',
    all: 'Tutti i prodotti',
  },
  origins: 'Partenze',
  order_package: 'Ordina in base a',
  ordered: 'Ord. fornitori',
  ordeded_tooltip: 'Ordini fornitore',
  overstock: 'Overstock',
  package_size_field: 'Campo di riferimento',
  package_round_ceil_tolerance: 'Tolleranza arrotondamento per eccesso',
  package_default_round_ceil_tolerance:
    'Tolleranza arrotondamento "Confezione"',
  preparation_from_warehouses: 'In prepar. da depositi',
  preparation_from_warehouses_tooltip: 'In preparazione da depositi',
  quantity: 'Esistenza',
  quantity_tooltip: 'Esistenza (Giacenza + Indisponibile)',
  routine: {
    selected: 'Routine selezionata',
  },
  session_empty: 'Non ci sono sessioni di lavoro salvate',
  simulate: 'Simula',
  simulate_tooltip: 'Avvia la simulazione con i filtri impostati',
  simulate_sync: 'Aggiorna i depositi e poi avvia la simulazione',
  simulation_type: 'Analizza come',
  simulation_types: {
    manual: 'Default',
    transfer: 'Trasferimenti automatici',
    refilling: 'Trasferimenti refilling',
  },
  steps: {
    general: 'Generale',
    logistics_classes: 'Classi logistiche',
    stock: 'Scorte',
    five_thresholds: '5 Soglie',
    speculative_orders: 'Ordini speculativi',
    warehouses: 'Magazzini',
    shuttles: 'Navette',
    fab_x: 'Filtro Fab X',
    view: 'Visualizzazione',
    refines: 'Perfeziona',
    discounts: 'Sconti',
  },
  transfer_package: 'Trasferisci in base a',
  type_d_as_c: 'Tratta i Tipi D come C',
  unavailability: 'Indisponibile',
  view_products: 'Prodotti da visualizzare',
  view_show_added_from: 'Mostra aggiunti da',
  view_cpa: 'Seleziona le CPA da visualizzare',
  view_table_format: 'Formattazione tabella',
  view_cpa_hint: 'Specifica le CPA da visualizzare, default: "%{default}"',
  virtual: {
    ignore_qtas: 'Ignora le giacenze',
    ignore_qtas_info: 'Ignora le giacenze, utilizza solamente le cpa e le date',
    references: 'Utilizza i magazzini',
  },
  warehouse_sort_field: 'Ordina il magazzino per',
  zero_moving: 'Zero moving',
  zero_moving_options: {
    0: 'Nessuno',
    360: '12 mesi',
    540: '18 mesi',
    720: '24 mesi',
  },
  distribution_projects_label: 'Progetti forzature',
  distribution_projects_hint: 'Seleziona un progetto',
  forcing_distribution_project: 'Progetto',
  forcing_code_label: 'Codice',
  forcing_description_label: 'Descrizione',
  forcing_id_label: 'id',
  forcing_request_by_label: 'Richiesto da',
  forcing_type_label: 'Tipo',
  forcing_created_at_label: 'Creato il',
  forcing_updated_at_label: 'Aggiornato il',
  forcing_entries_id: 'ID',
  forcing_entries_project_id: 'ID Progetto',
  forcing_entries_product_id: 'ID Prodotto',
  forcing_entries_warehouse_id: 'ID Magazzino',
  forcing_entries_quantity_min: 'Quantità minima',
  forcing_entries_quantity_max: 'Quantità massima',
  forcing_entries_date: 'Data inizio validità',
  forcing_entries_expires: 'Scadenza',
  forcing_entries_search: 'Filtra per codice CATI',
  b_forcing_project_detail: 'Dettaglio progetto',
  b_forcing_project_edit: 'Modifica progetto',
  warehouse_similitudes_label: 'Similitudine con altre filiali',
  web_analysis_label: 'Analisi web',
  scs_choise_label: 'Scelta SCS',
  save_missed: 'Non è stato possibile creare il progetto',
  forcing_distribution_project_created: 'Progetto {code} creato',
  upload_forcing_entries: 'Carica forzature',
  create_forcing_distribution_project: 'Crea progetto forzature',
  upload_file_for: 'Carica il file per {code} - {id}',
  upload_failed_for: 'Caricamento fallito per {code} - {id}',
  delete_forcing: 'Cancella forzature',
  delete_selected: 'Cancella selezionate',
  upload_deleted: 'Caricamento cancellazioni',
  hide_expired: 'Nascondi forzature scadute',
  upload_expired: 'Carica forzature scadute per il progetto: {code}',
  cati_code: 'Codice CATI',
  create_project: 'Crea progetto',
  upload_projects: 'Carica progetti',
  upload_file_ko: 'Caricamento file fallito :-(',
  upload_projects_file: 'Carica progetti forzature',
  uploaded_projects_file: 'Progetti forzature caricati',
  erase_projects: 'Cancella progetti selezionati',
  manage_warehouse_distributions: 'Gestione livelli',
  manage_white_lists: 'Gestione forzature',
  class_id: 'ID Classe merceologica',
  class_name: 'Classe merceologica',
  class_code: 'Classe merceologica',
  warehouse_id: 'ID Magazzino',
  minimum_logistics_class: 'Classe logistica minima',
  logistics_class: 'Classe logistica',
  upload_warehouse_levels: 'Carica livelli magazzino',
  upload_warehouse_levels_ko: 'Non è stato possibile caricare i livelli',
  upload_refilling_levels: 'Carica livelli refilling',
  upload_refilling_levels_ko: 'Non è stato possibile caricare i livelli',
  revision_days: 'Giorni di revisione',
  leading_time_days: 'Giorni principali',
  security_days: 'Giorni di sicurezza',
  sh1: 'SH 1',
  sh2: 'SH 2',
  sl1: 'SL 1',
  sl2: 'SL 2',
  upload_five_thresholds_coefficients: 'Carica coefficienti di soglia',
  filter_by_class_id: 'Filtra classe merceologica',
  update_minimum_logistics_class: 'Aggiorna classe logistica minima',
  five_thresholds_constraints: 'Vincoli 5 soglie',
  for_eoq: 'Per EOQ',
  min_items: 'Per quantità',
  min_months: 'Per mesi',
  max_months: 'Per max mesi',
  created_at: 'Creato il',
  updated_at: 'Aggiornato il',
  economic_order_quantity: 'Parametri EOQ',
  acq: 'Tempo medio d\'Acquisto',
  log: 'Tempo medio di Logistica',
  cost_i: 'Costo orario',
  cost_h: 'Costo percentuale del capitale',
  no_entries: 'Tutte le forzature sono valide',
  all_entries: 'Tutte le forzature sono scadute',
  some_entries: '{expired} forzature sono scadute su {total} totali',
  some_entries_archive_all: 'Archivia tutte le forzature',
  maximum_logistics_class: 'Classe logistica massima',
  total_projects_entries_count: 'Totale forzature',
  expired_count: 'Forzature scadute',
  empty_entries: 'Nessuna forzatura caricata per questo progetto',
  economic_order_quantity_upload: 'Carica parametri EOQ',
  economic_order_quantity_upload_ko: 'Non è stato possibile caricare i parametri EOQ',
  warehouse_level_1_id: 'ID Magazzino 1',
  warehouse_level_2_id: 'ID Magazzino 2',
  warehouse_level_1_code: 'Codice Magazzino 1',
  warehouse_level_2_code: 'Codice Magazzino 2',
  type: 'Tipo',
  warehouse_hierarchies_upload: 'Carica gerarchia magazzini',
  warehouse_hierarchies_upload_ko: 'Non è stato possibile caricare la gerarchia magazzini',
  lead_time_threshold: 'Soglia di lead time',
  update_maximum_logistics_class: 'Aggiorna classe logistica massima',
  coefficients_thresholds_branch: 'Riduzione Scorte di Sicurezza',
  security_days_perc: 'Giorni di sicurezza %',
  coefficients_thresholds_branch_upload: 'Carica Riduzione Scorte di Sicurezza',
  max_months_hub: 'Max mesi per hub',
}

export default order_bot
