import { RouteRecordRawArgumented } from 'src/types/route'
import useI18n from 'src/hooks/i18n'
import useRouteParser from 'src/hooks/routeParser'

const { t } = useI18n()
const { getPaginationCasts, getPaginationInject } = useRouteParser()

const module: RouteRecordRawArgumented = {
  path: 'economic-order-quantity',
  name: 'economicOrderQuantity',
  component: () =>
    import(
      /* webpackChunkName: "economic-order-quantity" */ 'pages/order-bot/EconomicOrderQuantity.vue'
    ),
  meta: {
    breadcrumb: {
      name: t('breadcrumb.order_bot.economic_order_quantity'),
      icon: 'mdi-square-circle',
    },
    auth: {
      permission: 'order_bot_manage',
    },
    inject: {
      query: {
        ...getPaginationInject(),
        sort: 'created_at',
        dir: 'desc',
      },
    },
    casts: {
      query: {
        ...getPaginationCasts(),
      },
    },
  },
  children: [
    {
      path: 'upload-economic-order-quantity',
      name: 'uploadyEconomicOrderQuantity',
      component: () =>
        import(
          /* webpackChunkName: "economic-order-quantity" */ 'pages/order-bot/EconomicOrderQuantityUpload.vue'
        ),
      meta: {
        breadcrumb: {
          icon: 'mdi-square-circle',
          name: t('breadcrumb.order_bot.economic_order_quantity_upload'),
        },
        auth: {
          permission: 'order_bot_manage',
        },
        casts: {
          query: {},
        },
      },
    },
  ],
}

export default module
