import { RouteRecordRawArgumented } from 'src/types/route'
import useI18n from 'src/hooks/i18n'
import useRouteParser from 'src/hooks/routeParser'

const { t } = useI18n()
const { getPaginationCasts, getPaginationInject } = useRouteParser()

const module: RouteRecordRawArgumented = {
  path: 'five-thresholds-constraints',
  name: 'fiveThresholdsConstraints',
  component: () =>
    import(
      /* webpackChunkName: "five-thresholds-constraints" */ 'pages/order-bot/FiveThresholdsConstraints.vue'
    ),
  meta: {
    breadcrumb: {
      name: t('breadcrumb.order_bot.five_thresholds_constraints'),
      icon: 'mdi-lock',
    },
    auth: {
      permission: 'order_bot_manage',
    },
    inject: {
      query: {
        ...getPaginationInject(),
        sort: 'created_at',
        dir: 'desc',
      },
    },
    casts: {
      query: {
        ...getPaginationCasts(),
      },
    },
  },
  children: [],
}

export default module
