import { RouteRecordRawArgumented } from 'src/types/route'
import useI18n from 'src/hooks/i18n'
import useRouteParser from 'src/hooks/routeParser'

const { t } = useI18n()
const { getPaginationCasts, getPaginationInject } = useRouteParser()

const module: RouteRecordRawArgumented = {
  path: 'warehouses-hierarchies-classes-merchs',
  name: 'warehousesHierarchiesClassesMerchs',
  component: () =>
    import(
      /* webpackChunkName: "warehouses-hierarchies-classes-merchs" */ 'pages/order-bot/WarehousesHierarchiesClassesMerchs.vue'
    ),
  meta: {
    breadcrumb: {
      name: t('breadcrumb.order_bot.warehouses_hierarchies_classes_merchs'),
      icon: 'mdi-safe-square-outline',
    },
    auth: {
      permission: 'order_bot_manage',
    },
    inject: {
      query: {
        ...getPaginationInject(),
        sort: 'created_at',
        dir: 'desc',
      },
    },
    casts: {
      query: {
        ...getPaginationCasts(),
      },
    },
  },
  children: [
    {
      path: 'upload-warehouses-hierarchies-classes-merchs',
      name: 'warehousesHierarchiesClassesMerchsUpload',
      component: () =>
        import(
          /* webpackChunkName: "upload-warehouses-hierarchies-classes-merchs" */ 'pages/order-bot/WarehousesHierarchiesClassesMerchsUpload.vue'
        ),
      meta: {
        breadcrumb: {
          icon: 'mdi-safe-square-outline',
          name: t(
            'breadcrumb.order_bot.create_forcing_distribution_project_massive'
          ),
        },
        auth: {
          permission: 'order_bot_manage',
        },
        casts: {
          query: {},
        },
      },
    },
  ],
}

export default module
