const breadcrumb = {
  add_f: 'Aggiungi nuova',
  add_m: 'Aggiungi nuovo',
  class_merch: {
    clusters: {
      index: 'Gestione cluster',
      create: 'Nuovo cluster',
      show: 'Dettagli cluster',
      edit: 'Modifica cluster',
      copy: 'Duplica cluster',
    },
  },
  order_bot: {
    compose: 'Order Bot',
    settings: 'Configurazione costi',
    create_forcing_distribution_project: 'Crea progetto forzature',
    create_forcing_distribution_project_massive: 'Carica progetti forzature',
    upload_forcing_distribution_project_entries: 'Carica forzature',
    manage_warehouse_distributions: 'Gestione perimetro teorico',
    manage_white_lists: 'Gestione forzature',
    five_thresholds_coefficients: 'Coefficienti 5 soglie',
    upload_five_thresholds_coefficients: 'Carica coefficienti 5 soglie',
    five_thresholds_constraints: 'Vincoli 5 soglie',
    economic_order_quantity: 'Parametri EOQ',
    warehouses_refilling_levels: 'Gestione livelli refilling',
    economic_order_quantity_upload: 'Carica Parametri EOQ',
    warehouses_hierarchies_classes_merchs: 'Gerarchie classi merceologiche',
    coefficients_thresholds_branch: 'Riduzione Scorte di Sicurezza',
  },
  pfa_rules: {
    index: 'Premi fornitori',
    create: 'Nuovo premio',
    show: 'Dettagli premio',
    edit: 'Modifica premio',
    upload: 'Carica fatturati',
  },
  show: 'Dettaglio',
  suppliers: {
    index: 'Fornitori',
    show: 'Dettagli fornitore',
    edit: 'Modifica fornitore',
  },
  suppliers_promos: {
    index: 'Promozioni',
    create: 'Nuova promozione',
    show: 'Dettagli promozione',
    edit: 'Modifica promozione',
  },
  carbody: {
    compare: 'Confronta Prezzi',
    upload: 'Carica Prezzi',
    index: 'Carrozzeria',
    manage_products: 'Crea nuovo prodotto',
    edit_product: 'Modifica cod. CATI/ Inser. nuovo Fornitore',
    tmp_products_index: 'Nuovi prodotti',
    manage_white_lists: 'Gestione forzature'
  },
  products_sizes: {
    index: 'Dimensione Prodotti'
  },
  traders: {
    index: 'Componi prezzi Traders'
  },
  teccom: {
    index: 'Seleziona fornitore ordine'
  },
  update: 'Modifica',
}

export default breadcrumb