const common = {
  active: 'Attivo',
  add: 'Aggiungi',
  advanced_settings: 'Impostazioni avanzate',
  all: 'Tutti',
  backward: 'Indietro',
  classes_merch: 'Classi merceologiche',
  classes_merch_add_tags: 'Aggiungi le classi (separate da una virgola)',
  groups_add_tags: 'Aggiungi i gruppi (separate da una virgola)',
  family_add_tags: 'Aggiungi le famiglie (separate da una virgola)',
  close: 'Chiudi',
  code: 'Codice',
  copy_of: 'Copia di {value}',
  completed: 'Operazione completata',
  cost: 'Costo',
  config: 'Configura',
  confirm_requested: 'Conferma richiesta',
  confirm_requested_message: 'Continuare?',
  continue: 'Continua',
  cpa: 'Cpa',
  create: 'Crea nuovo',
  csrf_token_missmatch_renewed: 'Rinnovo token completato, riprovare',
  csrf_token_missmatch_renewing: 'Tentativo di rinnovo automatico in corso',
  custom_value: 'Valore manuale',
  currency: 'Euro',
  description_note: 'Descrizione/Note',
  delete: 'Elimina',
  destroy: 'Elimina',
  destroyed: 'Eliminato',
  default_settings: 'Impostazioni base',
  default_sort: 'Ordinamento di default',
  description: 'Descrizione',
  direction: 'Direzione',
  discount: 'Sconto',
  donators: 'Donatori',
  duplicate: 'Duplica',
  export: 'Esporta',
  field_required: 'Campo obbligatorio',
  filter: 'Filtra',
  filter_values: 'Cerca tra i valori',
  filter_values_of: 'Cerca tra i valori di "{value}"',
  forward: 'Avanti',
  from: 'Dal',
  generic_error: 'Errore generico',
  loading: 'Caricamento in corso...',
  manufacturers: 'Produttori',
  manufacturers_add_tags: 'Aggiungi i produttori (separati da una virgola)',
  month: 'Mese',
  name: 'Nome',
  nc: 'NC',
  no: 'No',
  no_results: 'Nessun risultato',
  note: 'Note',
  or: 'oppure',
  order: 'Ordina',
  percentage: 'Percentuale',
  product_code: 'Codice prodotto',
  products: 'Prodotti',
  quantity: 'Quantità',
  quantity_short: 'Q.ta',
  quantity_min: 'Quantità minima',
  quantity_min_short: 'Q.ta min.',
  quit: 'Esci',
  range_time: 'Periodo',
  receptors: 'Riceventi',
  revenue: 'Fatturato',
  save: 'Salva',
  save_and_quit: 'Salva ed esci',
  save_updates: 'Salva Modifiche',
  search: 'Cerca',
  searching: 'Ricerca in corso...',
  select_date: 'Seleziona la data',
  select_year: "Seleziona l'anno",
  show_all: 'Mostra tutto',
  some_codes_missed: 'Alcuni codici non sono stati trovati',
  status: 'Stato',
  supplier_label: 'Fornitore',
  suppliers: 'Fornitori',
  suppliers_add_tags: 'Aggiungi i codici fornitore (separati da una virgola)',
  table: {
    show_columns: 'Colonne',
  },
  targets: 'Targets',
  to: 'Al',
  toggle_all: 'Tutti / Nessuno',
  toggle_all_f: 'Tutte / Nessuna',
  type: 'Tipo',
  undo: 'Annulla',
  unsaved_message: 'Le modifiche non sono state salvate, continuare?',
  unity: 'Unità',
  update: 'Modifica',
  upload: 'Carica',
  upload_file: 'Carica file',
  value: 'Valore',
  warehouse: 'Deposito',
  warehouse_of: 'Magazzino di: %{name}',
  warehouses: 'Depositi',
  warning: 'Attenzione',
  wrong_submitted_data: 'I dati inviati non sono corretti',
  year: 'Anno',
  yes: 'Si',
  cati_code: 'Codice CATI',
  KO: 'KO',
  OK: 'OK',
  submit: 'Invia dati',
  None: 'Nessuno',
  categories_add_tag: 'Aggiungi la categoria prodotto',
}

export default common
